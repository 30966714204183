import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';
import { DataProxy } from 'apollo-cache';
import _ from 'lodash';

import { Product, ShoppingList } from '../../types/data';
import { QUERY_PRODUCTS_IN, QUERY_ALL_PRODUCTS } from './queries';

interface MutationResponse {
  success: boolean;
  message?: string;
  __typename?: string;
}

export type MUTATION_ADD_PRODUCT_TO_LIST = {
  addProductTo: MutationResponse & {
    product: Product;
    shoppingList?: ShoppingList;
  };
};
export const MUTATION_ADD_PRODUCT_TO_LIST = {
  mutation: gql`
    mutation(
      $shoppingListId: ID
      $shoppingListName: String
      $productId: ID
      $productName: String
    ) {
      addProductTo(
        shoppingListId: $shoppingListId
        shoppingListName: $shoppingListName
        productId: $productId
        productName: $productName
      ) {
        success
        message
        product {
          id
          name
        }
      }
    }
  `,

  optimisticResponse: (
    apolloClient: ApolloClient<object>,
    { productId, productName }: { productId?: string; productName: string },
  ): MUTATION_ADD_PRODUCT_TO_LIST => {
    const { allProducts } = apolloClient.readQuery<QUERY_ALL_PRODUCTS>({
      query: QUERY_ALL_PRODUCTS,
    }) || { allProducts: [] };
    const product = allProducts.find(
      p => p.id === productId || p.name === productName,
    );
    return {
      addProductTo: {
        success: true,
        message: 'AddProductToMutation Optimistic OK',
        product: product || {
          id: `#OPTIMISTIC#${_.uniqueId()}`,
          name: productName,
          shoppingLists: {
            edges: [],
          },
          __typename: 'Product',
        },
        __typename: 'AddProductToMutationResponse',
      },
    };
  },

  update: (
    cache: DataProxy,
    { data: { addProductTo } }: { data: MUTATION_ADD_PRODUCT_TO_LIST },
  ): void => {
    const { productsIn } = cache.readQuery<QUERY_PRODUCTS_IN>({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
    }) || { productsIn: [] };
    cache.writeQuery({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
      data: {
        productsIn: productsIn.concat([{ node: addProductTo.product }]),
      },
    });
  },
};

export type MUTATION_REPLACE_PRODUCT_IN_LIST = {
  replaceProductIn: MutationResponse & {
    prevProduct: Product;
    product: Product;
    shoppingList?: ShoppingList;
  };
};
export const MUTATION_REPLACE_PRODUCT_IN_LIST = {
  mutation: gql`
    mutation(
      $shoppingListId: ID
      $shoppingListName: String
      $prevProductId: ID!
      $productId: ID
      $productName: String
    ) {
      replaceProductIn(
        shoppingListId: $shoppingListId
        shoppingListName: $shoppingListName
        prevProductId: $prevProductId
        productId: $productId
        productName: $productName
      ) {
        success
        message
        prevProduct {
          id
        }
        product {
          id
          name
        }
      }
    }
  `,

  optimisticResponse: (
    apolloClient: ApolloClient<object>,
    {
      prevProduct,
      productId,
      productName,
    }: { prevProduct: Product; productId?: string; productName: string },
  ): MUTATION_REPLACE_PRODUCT_IN_LIST => {
    const { allProducts } = apolloClient.readQuery<QUERY_ALL_PRODUCTS>({
      query: QUERY_ALL_PRODUCTS,
    }) || { allProducts: [] };
    const product = allProducts.find(
      p => p.id === productId || p.name === productName,
    );
    return {
      replaceProductIn: {
        success: true,
        message: 'ChangeProductInMutation Optimistic OK',
        prevProduct,
        product: product || {
          id: `#OPTIMISTIC#${_.uniqueId()}`,
          name: productName,
          shoppingLists: {
            edges: [],
          },
          __typename: 'Product',
        },
        __typename: 'ChangeProductInMutationResponse',
      },
    };
  },

  update: (
    cache: DataProxy,
    { data: { replaceProductIn } }: { data: MUTATION_REPLACE_PRODUCT_IN_LIST },
  ): void => {
    const { productsIn } = cache.readQuery<QUERY_PRODUCTS_IN>({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
    }) || { productsIn: [] };

    const prevProductIndex = productsIn.findIndex(
      ({ node: p }) => p.id === replaceProductIn.prevProduct.id,
    );

    const newProductsIn = [...productsIn];

    newProductsIn.splice(prevProductIndex, 1, {
      node: replaceProductIn.product,
    });

    cache.writeQuery({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
      data: {
        productsIn: newProductsIn,
      },
    });
  },
};

export type MUTATION_REMOVE_PRODUCT_FROM_LIST = {
  removeProductFrom: MutationResponse & {
    product: Product;
    shoppingList?: ShoppingList;
  };
};
export const MUTATION_REMOVE_PRODUCT_FROM_LIST = {
  mutation: gql`
    mutation($shoppingListId: ID, $shoppingListName: String, $productId: ID) {
      removeProductFrom(
        shoppingListId: $shoppingListId
        shoppingListName: $shoppingListName
        productId: $productId
      ) {
        success
        message
        product {
          id
          name
        }
      }
    }
  `,

  optimisticResponse: (
    apolloClient: ApolloClient<object>,
    { product }: { product: Product },
  ): MUTATION_REMOVE_PRODUCT_FROM_LIST => {
    return {
      removeProductFrom: {
        success: true,
        message: 'RemoveProductFromMutation Optimistic OK',
        product,
        __typename: 'RemoveProductFromMutationResponse',
      },
    };
  },

  update: (
    cache: DataProxy,
    {
      data: { removeProductFrom },
    }: { data: MUTATION_REMOVE_PRODUCT_FROM_LIST },
  ): void => {
    const { productsIn } = cache.readQuery<QUERY_PRODUCTS_IN>({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
    }) || { productsIn: [] };
    cache.writeQuery({
      query: QUERY_PRODUCTS_IN,
      variables: {
        shoppingList: 'Courses',
      },
      data: {
        productsIn: productsIn.filter(
          ({ node: product }) => product.id !== removeProductFrom.product.id,
        ),
      },
    });
  },
};
