import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

type HeaderProps = React.PropsWithChildren<{
  title: string;
}>;

const Header: React.FC<HeaderProps> = ({ title, children }: HeaderProps) => {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          className="header-back"
          color="inherit"
          aria-label="Back"
          component={Link}
          to="/"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          className="header-title"
          noWrap
        >
          {title}
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
