import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cx from 'clsx';
import { ClassName } from '../../../types/style';

const useStyles = makeStyles(() =>
  createStyles({
    small: {
      margin: '0 auto',
      maxWidth: 800,
    },
  }),
);

type SizeWrapperProps = React.PropsWithChildren<{
  small?: boolean;
  className?: ClassName;
}>;

const SizeWrapper: React.FC<SizeWrapperProps> = ({
  small = false,
  className = '',
  children,
}: SizeWrapperProps) => {
  const classes = useStyles();
  if (!small) {
    return <>{children}</>;
  }
  return (
    <div className={cx(className, { [classes.small]: small })}>{children}</div>
  );
};

export default SizeWrapper;
