import { useState, useEffect, useCallback } from 'react';

export type WindowSize = {
  width: number | undefined;
  height: number | undefined;
};

// eslint-disable-next-line import/prefer-default-export
export function useWindowSize({ liveMeasure = true } = {}): WindowSize {
  const isClient = typeof window === 'object';

  const getSize = useCallback((): WindowSize => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState<WindowSize>(getSize); // Lazy initialization (function)

  useEffect(() => {
    if (!isClient) {
      return (): void => {};
    }

    const handleResize = (): void => {
      setWindowSize(getSize());
    };

    if (liveMeasure) {
      window.addEventListener('resize', handleResize);
    }
    return (): void => {
      if (liveMeasure) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [getSize, isClient, liveMeasure]);

  return windowSize;
}
