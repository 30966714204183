import React, { ReactNode, ChangeEvent, useState, useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete';

import { Product } from '../../types/data';
import {
  MUTATION_ADD_PRODUCT_TO_LIST,
  MUTATION_REPLACE_PRODUCT_IN_LIST,
  MUTATION_REMOVE_PRODUCT_FROM_LIST,
} from '../../lib/graphql/mutations';

type ProductOrString = Product | string;

const filter = createFilterOptions<ProductOrString>();

const MIN_CHAR_TO_OPEN = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: '1rem',
      color: theme.palette.primary.main,
    },
  }),
);

type EditItemProps = {
  product?: Product;
  suggestedProducts: Product[];
};

const EditItem: React.FC<EditItemProps> = ({
  product,
  suggestedProducts,
}: EditItemProps) => {
  const classes = useStyles();

  const apolloClient = useApolloClient();

  const [
    addProductTo,
    // {
    //   loading: addProductToLoading,
    //   data: addProductToResult,
    //   error: addProductToError,
    // },
  ] = useMutation<MUTATION_ADD_PRODUCT_TO_LIST>(
    MUTATION_ADD_PRODUCT_TO_LIST.mutation,
    { update: MUTATION_ADD_PRODUCT_TO_LIST.update },
  );

  const [
    replaceProductIn,
    // {
    //   loading: replaceProductInLoading,
    //   data: replaceProductInResult,
    //   error: replaceProductInError,
    // },
  ] = useMutation<MUTATION_REPLACE_PRODUCT_IN_LIST>(
    MUTATION_REPLACE_PRODUCT_IN_LIST.mutation,
    { update: MUTATION_REPLACE_PRODUCT_IN_LIST.update },
  );

  const [
    removeProductFrom,
    // {
    //   loading: removeProductFromLoading,
    //   data: removeProductFromResult,
    //   error: removeProductFromError,
    // },
  ] = useMutation<MUTATION_REMOVE_PRODUCT_FROM_LIST>(
    MUTATION_REMOVE_PRODUCT_FROM_LIST.mutation,
    { update: MUTATION_REMOVE_PRODUCT_FROM_LIST.update },
  );

  const addEntry = useCallback(
    (value: string, id?: string) => {
      console.info(
        `Adding: Product ${value} (#${id || 'NEW'}) to shopping list.`,
      );
      addProductTo({
        variables: {
          shoppingListName: 'Courses',
          productId: id,
          productName: value,
        },
        optimisticResponse: MUTATION_ADD_PRODUCT_TO_LIST.optimisticResponse(
          apolloClient,
          {
            productId: id,
            productName: value,
          },
        ),
      });
    },
    [addProductTo, apolloClient],
  );

  const changeEntry = useCallback(
    (value: string, id?: string) => {
      if (!product) {
        return;
      }
      console.info(
        `Changing: Product ${product.name} (#${product.id}) to ${value} (#${
          id || 'NEW'
        }) in shopping list.`,
      );
      replaceProductIn({
        variables: {
          shoppingListName: 'Courses',
          prevProductId: product.id,
          productId: id,
          productName: value,
        },
        optimisticResponse: MUTATION_REPLACE_PRODUCT_IN_LIST.optimisticResponse(
          apolloClient,
          {
            prevProduct: product,
            productId: id,
            productName: value,
          },
        ),
      });
    },
    [product, replaceProductIn, apolloClient],
  );

  const deleteEntry = useCallback(() => {
    if (product?.id) {
      console.info(
        `Deleting: Product ${product.name} '#${product.id} from shopping list.`,
      );
      removeProductFrom({
        variables: { shoppingListName: 'Courses', productId: product.id },
        optimisticResponse: MUTATION_REMOVE_PRODUCT_FROM_LIST.optimisticResponse(
          apolloClient,
          { product },
        ),
      });
    }
  }, [product, removeProductFrom, apolloClient]);

  // TODO: Focus new after clear/add

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [autocompleteValue, setValue] = useState<ProductOrString | undefined>(
    product,
  );

  const [inputValue, setInputValue] = useState('');
  const handleInputValueChange = useCallback(
    (
      event: ChangeEvent<{}>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // console.log('handleInputValueChange', product, {
      //   event,
      //   value,
      //   reason,
      // });

      if (reason === 'clear' && product) {
        setInputValue(product.name);
      } else if (reason === 'reset' && !product) {
        setInputValue('');
      } else {
        setInputValue(value);
      }

      if (reason === 'clear' || reason === 'reset') {
        setOpen(false);
      } else if (value.length >= MIN_CHAR_TO_OPEN) {
        setOpen(true);
      }
    },
    [product],
  );

  const handleChangeValue = useCallback(
    (
      event: ChangeEvent<{}>,
      rawValue: Product | string | null,
      reason: AutocompleteChangeReason,
    ): void => {
      // console.log('handleChangeValue', {
      //   event,
      //   rawValue,
      //   reason,
      // });

      const escapePressed =
        ((event as unknown) as { key: string }).key === 'Escape';

      // Change comes from input: event.nativeEvent.type === 'input'
      const clearContent = reason === 'clear' && (!product || !escapePressed);

      // Product deleted
      if (rawValue === null) {
        // Do something only if it was NOT a new line
        if (clearContent && product) {
          deleteEntry();
        }
        return;
      }

      const value = typeof rawValue === 'string' ? rawValue : rawValue.name;

      // Product selected
      if (['create-option', 'select-option'].includes(reason)) {
        if (!product) {
          addEntry(
            value,
            (typeof rawValue === 'object' && rawValue.id) || undefined,
          );
          setValue(undefined);
        } else {
          changeEntry(
            value,
            (typeof rawValue === 'object' && rawValue.id) || undefined,
          );
          setValue(rawValue);
        }
      }
    },
    [product, addEntry, changeEntry, deleteEntry],
  );

  return (
    <Autocomplete
      id={product?.id ?? `new product ${_.uniqueId()}`} // Helps for accessibility
      open={open}
      freeSolo
      options={product ? suggestedProducts.concat(product) : suggestedProducts}
      filterOptions={(options, params): ProductOrString[] => {
        const filtered = filter(options, params);

        // If inputValue is NOT empty AND different to all other options, propse to create it
        if (
          params.inputValue !== '' &&
          options.findIndex(
            p => typeof p !== 'string' && p.name === params.inputValue,
          ) === -1
        ) {
          filtered.splice(1, 0, `Ajouter: ${params.inputValue}`);
        }

        return filtered;
      }}
      getOptionLabel={(option: ProductOrString): string =>
        typeof option === 'string' ? option : option.name
      }
      renderOption={(option: ProductOrString): ReactNode =>
        typeof option === 'string' ? (
          <span style={{ color: 'red' }}>{option}</span>
        ) : (
          option.name
        )
      }
      autoHighlight
      renderInput={(params): React.ReactNode => (
        <InputBase
          id={params.id}
          inputProps={params.inputProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params.InputProps}
          fullWidth
          disabled={params.disabled}
          placeholder="Ajouter un produit"
          classes={{ root: classes.input }}
        />
      )}
      inputValue={inputValue}
      onInputChange={handleInputValueChange}
      value={autocompleteValue}
      onChange={handleChangeValue}
      disableClearable={!autocompleteValue && !inputValue}
      clearOnEscape
      clearText="Effacer"
      onClose={handleClose}
      // onHighlightChange={(...args) => {
      //   console.log('handleHighlightChange', args);
      // }}
      // onOpen={(...args) => {
      //   console.log('handleOpen', args);
      // }}
    />
  );
};

export default EditItem;
