import React, {
  useState,
  useCallback,
  // useMemo,
  // MouseEvent,
  // TouchEvent,
  // useRef,
} from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Add from '@material-ui/icons/Add';

import EditItem from './EditItem';
// import AddItem from './AddItem';

import { Product } from '../../types/data';

const useStyles = makeStyles(() =>
  createStyles({
    add: {
      marginLeft: -12,
      padding: 9,
      display: 'inline-flex',
    },
  }),
);

type ListItemProps = {
  product?: Product;
  remainingProducts: Product[];
};

const ListItem: React.FC<ListItemProps> = ({
  product,
  remainingProducts,
}: ListItemProps) => {
  const classes = useStyles();
  // const inputRef = useRef<HTMLInputElement>(null);

  const [checked, setChecked] = useState<boolean>(false);
  const handleClickCheck = useCallback(() => setChecked(!checked), [
    setChecked,
    checked,
  ]);

  // const isNew = useMemo(() => !product, [product]);

  // const handleClick = useCallback(
  //   (event: MouseEvent | TouchEvent) => {
  //     const inputEl = inputRef.current;
  //     if (inputEl) {
  //       inputEl.focus();
  //     }

  //     // TODO: Change to editMode
  //     if (!productValue) {
  //       return;
  //     }

  //     const el = event.currentTarget.closest('[data-value]');
  //     if (!(el instanceof HTMLElement)) return;

  //     const { value: dataValue } = el?.dataset ?? {};
  //     if (dataValue === undefined) return;

  //     console.log('Check', dataValue);

  //     // const currentIndex = checked.indexOf(dataValue);
  //     // const newChecked = [...checked];

  //     // if (currentIndex === -1) {
  //     //   newChecked.push(dataValue);
  //     // } else {
  //     //   newChecked.splice(currentIndex, 1);
  //     // }

  //     setChecked(!checked);
  //   },
  //   [inputRef, productValue, checked],
  // );

  const labelId = `checkbox-list-label-${product?.id ?? 'new'}`;

  return (
    <MuiListItem
      // role="button"
      dense
      // button
      // onClick={handleClick}
      // data-value={product.?}
    >
      <ListItemIcon>
        {product ? (
          <Checkbox
            edge="start"
            checked={checked}
            onClick={handleClickCheck}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        ) : (
          <span className={classes.add}>
            <Add />
          </span>
        )}
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={
          <EditItem product={product} suggestedProducts={remainingProducts} />
          //  || <AddItem products={remainingProducts} />
        }
      />
      {/* <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <CommentIcon />
                </IconButton>
              </ListItemSecondaryAction> */}
    </MuiListItem>
  );
};

export default ListItem;
