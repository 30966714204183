import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Header from '../structure/Header';
import ContentWrapper from '../../lib/theme/structure/ContentWrapper';

import { useWindowSize } from '../../lib/hooks/use-window-size';

import List from '../Shop/List';

const Shop: React.FC = () => {
  const theme = useTheme();
  const { width } = useWindowSize();

  const breakpointWidth = theme.breakpoints.getName(
    width || theme.breakpoints.widths.xl,
  );
  const smallScreen = ['xs', 'sm'].includes(breakpointWidth);

  return (
    <div>
      <Header title="Faire ses courses" />
      <ContentWrapper>
        <Paper
          square
          elevation={
            // && (!hasDoneItems || !doneExpanded)
            smallScreen ? 0 : 1
          }
        >
          <List />
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default Shop;
