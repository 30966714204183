import React from 'react';
import cx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Style, ClassName } from '../../../types/style';
import ConditionnalWrapper from '../../ConditionnalWrapper';
import SizeWrapper from './SizeWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      width: '100%',

      [`${theme.breakpoints.down('xs')}`]: {
        padding: theme.spacing(0, 1),
      },
      [`${theme.breakpoints.up('xs')}`]: {
        maxWidth: theme.breakpoints.widths.xs,
      },
      [`${theme.breakpoints.up('sm')}`]: {
        maxWidth: theme.breakpoints.widths.sm,
      },
      [`${theme.breakpoints.up('md')}`]: {
        maxWidth: theme.breakpoints.widths.md,
      },
      [`${theme.breakpoints.up('lg')}`]: {
        maxWidth: theme.breakpoints.widths.lg,
      },
      [`${theme.breakpoints.up('xl')}`]: {
        maxWidth: theme.breakpoints.widths.xl,
      },
    },

    padding: {
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& + &': {
        paddingTop: 0,
      },
    },
    extraPadding: {
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    center: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',

      '& > *': {
        flex: '0 0 auto',
      },
    },
    // xs: {
    //   width: theme.breakpoints.widths.xs,
    //   maxWidth: '100%',
    // },
    // sm: {
    //   width: theme.breakpoints.widths.sm,
    //   maxWidth: '100%',
    // },
    // md: {
    //   width: theme.breakpoints.widths.md,
    //   maxWidth: '100%',
    // },
    // lg: {
    //   width: theme.breakpoints.widths.lg,
    //   maxWidth: '100%',
    // },
    // xl: {
    //   width: theme.breakpoints.widths.xl,
    //   maxWidth: '100%',
    // },
  }),
);

type ContentWrapperProps = {
  center?: boolean;
  small?: boolean;
  padding?: boolean;
  extraPadding?: boolean;
  className?: ClassName;
  smallClassName?: ClassName;
  style?: Style;
  // React
  children?: React.ReactNode;
};

/**
 * ContentWrapper are for **content**, not for sections
 * Meaning that sibblings ContentWrappers will be considered
 * "in the same section"
 */
const ContentWrapper: React.FC<ContentWrapperProps> = ({
  center = false,
  small,
  padding = false,
  extraPadding = false,
  style = {},
  className = '',
  smallClassName = '',
  children,
}: ContentWrapperProps) => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.center]: center,
          [classes.padding]: padding,
          [classes.extraPadding]: extraPadding,
        },
        className,
      )}
      style={style}
    >
      <ConditionnalWrapper
        values={{ small: !!small }}
        components={{
          small: {
            Component: SizeWrapper,
            props: { small, className: smallClassName },
          },
        }}
      >
        {children}
      </ConditionnalWrapper>
    </div>
  );
};

export default ContentWrapper;
