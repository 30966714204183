import _findLast from 'lodash/findLast';
import memoizeOne from 'memoize-one';

import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  Breakpoint,
  BreakpointValues,
} from '@material-ui/core/styles/createBreakpoints';

import { createInsetShadowsFrom } from './utils';
import {
  primaryColor,
  secondaryColor,
  commonColors,
  specialColors,
  textColorsMixin,
} from './colors';

// Avenir font and SNCF icons
import './fonts/fonts.scss';

// Used for dev, in console: window.MUI_THEME
declare global {
  interface Window {
    MUI_THEME: Theme;
  }
}

let fontFamily = '';

function loadRobotoFont(): void {
  // For SSR
  if (typeof window !== 'undefined') {
    const fonts = {
      google: {
        families: ['Roboto:300,400,500', 'Exo 2:600'],
      },
    };
    // eslint-disable-next-line global-require
    import('webfontloader').then(WebFontLoader => {
      WebFontLoader.load(fonts);
    });
  }
}

function augmentTheme(muiTheme: Theme): void {
  const { contrastText } = muiTheme.palette.augmentColor({
    main: muiTheme.palette.text.primary,
  });
  muiTheme.palette.text.contrastText = contrastText;

  if (!muiTheme.breakpoints.widths) {
    muiTheme.breakpoints.widths = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
  }
  muiTheme.breakpoints.keys.forEach(key => {
    if (!muiTheme.breakpoints.widths[key]) {
      muiTheme.breakpoints.widths[key] = muiTheme.breakpoints.values[key];
    }
  });

  muiTheme.breakpoints.getName = (
    width: number,
    {
      keys = muiTheme.breakpoints.keys,
      values = muiTheme.breakpoints.values,
    }: {
      keys: Breakpoint[];
      values: BreakpointValues;
    } = muiTheme.breakpoints,
  ): Breakpoint => _findLast(keys, key => values[key] <= width) as Breakpoint;

  muiTheme.transitions.duration.instant = 50;
  muiTheme.transitions.duration.long = 400;

  muiTheme.insetShadows = createInsetShadowsFrom(muiTheme.shadows);
}

interface GetThemeOptions {
  withRobotoFallbackFont?: boolean;
}

/**
 * Memoized function to set the Material-UI lib theme
 * http://www.material-ui.com/#/customization/themes
 *    Load custom fonts
 *    Apply custom styles (colors mainly)
 * @return  {object}  muiTheme
 * @author Sylvain Pont
 */
const getTheme = memoizeOne(
  ({ withRobotoFallbackFont = false }: GetThemeOptions = {}) => {
    const SPACING = 8;
    const BORDER_RADIUS = 7;
    const FONT_SIZE = 16;
    const FONT_FAMILY = 'Roboto';

    let fallbackFontFamily = 'Helvetica, Arial, sans-serif';
    if (withRobotoFallbackFont || FONT_FAMILY === 'Roboto') {
      loadRobotoFont();
      fallbackFontFamily = `Roboto, ${fallbackFontFamily}`;
    }
    fontFamily = `${FONT_FAMILY}, ${fallbackFontFamily}`;

    // Make mui's functions available
    const mui: Theme = createMuiTheme();
    const muiTheme: Theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1440,
        },
        // Max widths according to breakpoints (Automatically set to breakpoints if not set)
        widths: {
          xs: 310,
          sm: 510,
          md: 800,
          lg: 1000,
          xl: 1024,
        },
      },
      palette: {
        primary: {
          // light, dark, and contrastText entries, if not set, will be generated
          main: primaryColor,
        },
        secondary: {
          // light, dark, and contrastText entries, if not set, will be generated
          main: secondaryColor,
        },
        common: {
          ...commonColors,
        },
        background: {
          default: commonColors.lightWhite,
          paper: commonColors.mediumWhite,
          // light: commonColors.mediumWhite,
          // dark: commonColors.mediumBlack,
        },
        text: textColorsMixin(
          ((): string => {
            const { dark /* #006978 */ } = mui.palette.augmentColor({
              main: secondaryColor,
            });
            return dark;
          })(),
        ),
        specials: {
          ...specialColors,
        },
      },
      spacing: SPACING,
      shape: {
        borderRadius: BORDER_RADIUS,
      },
      dropShadow: 'drop-shadow(15px 15px 20px rgba(0,0,0,0.03))',
      typography: {
        fontFamily,
        fontSize: FONT_SIZE, // Will be expressed in rem in the body
        htmlFontSize: FONT_SIZE, // Used to calculate ^ (body font-size in rem)
      },
    });

    muiTheme.mixins = {
      ...(muiTheme.mixins || {}),

      // toolbar: {
      //   height: 140,
      //   padding: muiTheme.spacing(5, 8),
      //   [muiTheme.breakpoints.down('lg')]: {
      //     padding: muiTheme.spacing(4, 4),
      //   },
      //   [muiTheme.breakpoints.down('sm')]: {
      //     height: 100,
      //     padding: muiTheme.spacing(4, 4),
      //   },
      // },
    };

    muiTheme.props = {
      ...(muiTheme.props || {}),

      MuiTypography: {
        variant: 'body1',
      },

      MuiAppBar: {
        elevation: 0,
      },

      MuiLink: {
        underline: 'none',
      },
    };

    muiTheme.overrides = {
      ...(muiTheme.overrides || {}),

      MuiTypography: {
        // Page title
        h1: {
          fontSize: '2.5rem',
          fontWeight: muiTheme.typography.fontWeightBold,
          lineHeight: '1.5',
          color: 'inherit',
          marginBottom: muiTheme.spacing(4),
        },
        // Home hero + Home titles
        h2: {
          fontSize: '2.2rem',
          fontWeight: muiTheme.typography.fontWeightBold,
          lineHeight: '1.5',
          color: 'inherit',
        },
        // Home news title
        h3: {
          fontSize: '1.7rem',
          fontWeight: muiTheme.typography.fontWeightRegular,
          lineHeight: '1.2',
          color: 'inherit',
        },
        // Home values title
        h4: {
          fontSize: '1.1rem',
          fontWeight: muiTheme.typography.fontWeightBold,
          lineHeight: '1.2',
          color: muiTheme.palette.primary.main,
        },
        // h5: {
        //   fontSize: '1rem',
        //   fontWeight: muiTheme.typography.fontWeightBold,
        //   lineHeight: '1.1',
        //   color: muiTheme.palette.text.primary,
        // },
        // h6: {
        //   fontSize: '1rem',
        //   fontWeight: muiTheme.typography.fontWeightBold,
        //   lineHeight: '1.1',
        //   color: muiTheme.palette.text.primary,
        // },
        subtitle1: {
          // Home news dates
          fontSize: '0.95rem',
          fontWeight: muiTheme.typography.fontWeightMedium,
          lineHeight: '1',
          marginBottom: muiTheme.spacing(0.5),
          color: muiTheme.palette.primary.main,
          textTransform: 'uppercase',
        },
        subtitle2: {
          // Page title back link
          fontSize: '0.95rem',
          fontWeight: muiTheme.typography.fontWeightMedium,
          lineHeight: '1',
          color: 'inherit',
        },
        body1: {
          fontSize: '1rem',
          fontWeight: muiTheme.typography.fontWeightRegular,
          margin: 0,
          marginTop: '1rem',
        },
        // body2: {
        //   fontSize: '0.9rem',
        //   fontWeight: muiTheme.typography.fontWeightRegular,
        // },
        button: {
          fontSize: '1rem',
          lineHeight: 1,
          fontWeight: muiTheme.typography.fontWeightBold,
          letterSpacing: '0.05em',
        },
        // caption: {
        //   fontSize: '0.85rem',
        //   lineHeight: 1,
        // },
        // overline: {
        //   fontSize: '0.85rem',
        //   lineHeight: 1,
        // },
      },

      MuiAppBar: {
        colorDefault: {
          backgroundColor: muiTheme.palette.background.default,
          color: muiTheme.palette.text.primary,
        },
      },
    };

    augmentTheme(muiTheme);

    if (process.env.NODE_ENV !== 'production') {
      window.MUI_THEME = muiTheme;
    }

    return muiTheme;
  },
);

function getFontFamily(): string {
  if (!fontFamily) {
    getTheme();
  }
  return fontFamily;
}

export { getTheme, getFontFamily, augmentTheme };
