import React, { useMemo } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import MuiList from '@material-ui/core/List';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import {
  QUERY_PRODUCTS_IN,
  QUERY_ALL_PRODUCTS,
} from '../../lib/graphql/queries';
import ListItem from './ListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
  }),
);

type ListProps = { shoppingMode?: boolean };

const List: React.FC<ListProps> = ({ shoppingMode = false }: ListProps) => {
  if (shoppingMode) {
    // TODO: Shopping mode to remove line edition and set listItem as button
    console.log(List, { shoppingMode });
  }

  const classes = useStyles();

  const shopProducts = useQuery<QUERY_PRODUCTS_IN>(QUERY_PRODUCTS_IN, {
    variables: {
      shoppingList: 'Courses',
    },
  });
  const allProducts = useQuery<QUERY_ALL_PRODUCTS>(QUERY_ALL_PRODUCTS);
  const remainingProducts = useMemo(
    () =>
      _.differenceBy(
        allProducts.data?.allProducts ?? [],
        shopProducts.data?.productsIn ?? [],
        'name',
      ),
    [allProducts, shopProducts],
  );

  return (
    <MuiList className={classes.root} disablePadding>
      {shopProducts.data?.productsIn.map(({ node: product }) => (
        <ListItem
          key={product.name}
          product={product}
          remainingProducts={remainingProducts}
        />
      ))}
      <ListItem remainingProducts={remainingProducts} />
    </MuiList>
  );
};

export default List;
