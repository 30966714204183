import color from 'color';

const commonColorsBase = {
  lightWhite: 'rgb(241, 241, 241)',
  mediumWhite: 'rgb(248, 248, 248)',
  hardWhite: 'rgb(255, 255, 255)',

  darkGrey: 'rgb(62, 62, 62)',

  lightBlack: 'rgb(44, 44, 44)',
  mediumBlack: 'rgb(22, 22, 22)',
  hardBlack: 'rgb(0, 0, 0)',
};

const commonColors = {
  ...commonColorsBase,
  white: commonColorsBase.mediumWhite,
  black: commonColorsBase.mediumBlack,
};

const specialColors = {
  none: '',
};

const primaryColor = 'rgb(194, 24, 91)'; // pink[700].main
const secondaryColor = 'rgb(0, 105, 119)'; // cyan[700].dark

const textColorsMixin = (
  base: string,
): {
  primary: string;
  secondary: string;
  disabled: string;
  hint: string;
  contrastText: 'placeholder';
} => ({
  primary: color(base)
    // .alpha(0.87)
    .string(),
  secondary: color(base)
    // .negate()
    // .alpha(0.54)
    .alpha(0.7)
    .string(),
  hint: color(base)
    // .alpha(0.46)
    .alpha(0.55)
    .string(),
  disabled: color(base)
    // .alpha(0.38)
    .alpha(0.4)
    .string(),
  contrastText: 'placeholder', // Generated based on primaryColor
});

export {
  primaryColor,
  secondaryColor,
  commonColors,
  specialColors,
  textColorsMixin,
};
