import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache({});
const link = new HttpLink({
  uri: 'https://meals.pont.me/graphql',
  credentials: 'same-origin',
});

const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: true,
});

export default client;
