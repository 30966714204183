import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, getTheme, MuiThemeProvider } from './lib/theme';

import client from './lib/graphql/client';

import Shop from './components/Routes/Shop';

const App: React.FC = () => {
  const theme = getTheme();
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Shop />
        </BrowserRouter>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default App;
