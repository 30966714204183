import gql from 'graphql-tag';
import { Product, ProductEdge } from '../../types/data';

export type QUERY_PRODUCTS_IN = {
  productsIn: ProductEdge[];
};
export const QUERY_PRODUCTS_IN = gql`
  query ProductsIn($shoppingList: String!) {
    productsIn(shoppingList: $shoppingList) {
      node {
        id
        name
      }
      quantity
      done
    }
  }
`;

export type QUERY_ALL_PRODUCTS = {
  allProducts: Product[];
};
export const QUERY_ALL_PRODUCTS = gql`
  query AllProducts {
    allProducts {
      id
      name
    }
  }
`;
